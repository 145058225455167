<template>
  <div class="airline mb-30">
    <a-row :gutter="[20, 0]">
      <a-col :span="24">
        <h3 class="title">
          <a-row>
            <a-col :span="12"> CIAS AÉREAS </a-col>
            <a-col class="a-right" :span="12">
              <a-button
                v-if="flight_airlines.length < 10"
                size="small"
                @click="addRow(flight_airlines.length)"
                type="link"
              >
                Adicionar
              </a-button>
            </a-col>
          </a-row>
        </h3>
        <a-row
          v-for="airline in flight_airlines"
          :key="airline.id"
          :class="`traveller
                            traveller-${airline.id}`"
          :gutter="[5, 0]"
        >
          <a-col :span="10">
            <a-form-item>
              <label
                :class="
                  form.getFieldValue(
                    `flight_${flightSectionID}_airline_company_name_${airline.id}`
                  )
                    ? 'filled'
                    : ''
                "
                >Razão Social</label
              >
              <a-input
                class="travel-input"
                placeholder="Razão Social"
                v-decorator="[
                  `flight_${flightSectionID}_airline_company_name_${airline.id}`,
                  {
                    rules: [
                      {
                        required: dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]"
              >
                <template slot="prefix">#{{ airline.id }}</template>
              </a-input>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <span class="travel-input">
                <label
                  :class="
                    form.getFieldValue(
                      `flight_${flightSectionID}_airline_trading_name_${airline.id}`
                    )
                      ? 'filled'
                      : ''
                  "
                  >Nome Fantasia</label
                >
                <a-input
                  class="travel-input"
                  placeholder="Nome Fantasia"
                  v-decorator="[
                    `flight_${flightSectionID}_airline_trading_name_${airline.id}`,
                    {
                      rules: [
                        {
                          required: dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="7">
            <a-form-item>
              <span class="travel-input">
                <label
                  :class="
                    form.getFieldValue(
                      `flight_${flightSectionID}_airline_CNPJ_${airline.id}`
                    )
                      ? 'filled'
                      : ''
                  "
                  >CNPJ</label
                >
                <a-input
                  class="travel-input"
                  placeholder="CNPJ"
                  v-mask="'##.###.###/####-##'"
                  v-decorator="[
                    `flight_${flightSectionID}_airline_CNPJ_${airline.id}`,
                    {
                      rules: [
                        {
                          required: dynamicRequiredField(),
                          message: 'Obrigatório',
                        },
                      ],
                    },
                  ]"
                >
                </a-input>
              </span>
            </a-form-item>
          </a-col>

          <a-col :span="1" style="text-align: center">
            <a-button
              v-if="
                airline.id === flight_airlines.length &&
                flight_airlines.length > 1
              "
              size="small"
              type="danger"
              shape="circle"
              icon="delete"
              @click="removeRow(airline.id)"
            >
            </a-button>

            <a-tooltip
              v-if="airline.id == 1"
              placement="topRight"
              title="Selecionar cia aérea"
            >
              <a-button
                size="small"
                type="primary"
                shape="circle"
                style="left: -2px; top: 8px"
                :disabled="true"
              >
                <i class="fas fa-plane"></i>
              </a-button>
            </a-tooltip>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "FlightAirlinesForm",
  props: {
    contract: Object,
    form: Object,
    flight_airlines: Array,
    flightSectionID: Number,
  },
  data() {
    return {};
  },
  methods: {
    removeRow(id) {
      this.$emit("removeFlightAirline", id, this.flightSectionID);
    },
    addRow(id) {
      this.$emit("updateFlightAirline", id, this.flightSectionID);
    },
    dynamicRequiredField() {
      let flag = this.form.getFieldValue("contracted_services");

      if (flag != undefined) {
        flag = this.form
          .getFieldValue("contracted_services")
          .includes("flight");
      } else {
        flag = false;
      }

      return flag;
    },
  },
};
</script>
