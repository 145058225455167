var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"airline mb-30"},[_c('a-row',{attrs:{"gutter":[20, 0]}},[_c('a-col',{attrs:{"span":24}},[_c('h3',{staticClass:"title"},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_vm._v(" CIAS AÉREAS ")]),_vm._v(" "),_c('a-col',{staticClass:"a-right",attrs:{"span":12}},[(_vm.flight_airlines.length < 10)?_c('a-button',{attrs:{"size":"small","type":"link"},on:{"click":function($event){return _vm.addRow(_vm.flight_airlines.length)}}},[_vm._v("\n              Adicionar\n            ")]):_vm._e()],1)],1)],1),_vm._v(" "),_vm._l((_vm.flight_airlines),function(airline){return _c('a-row',{key:airline.id,class:`traveller
                          traveller-${airline.id}`,attrs:{"gutter":[5, 0]}},[_c('a-col',{attrs:{"span":10}},[_c('a-form-item',[_c('label',{class:_vm.form.getFieldValue(
                  `flight_${_vm.flightSectionID}_airline_company_name_${airline.id}`
                )
                  ? 'filled'
                  : ''},[_vm._v("Razão Social")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                `flight_${_vm.flightSectionID}_airline_company_name_${airline.id}`,
                {
                  rules: [
                    {
                      required: _vm.dynamicRequiredField(),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]),expression:"[\n                `flight_${flightSectionID}_airline_company_name_${airline.id}`,\n                {\n                  rules: [\n                    {\n                      required: dynamicRequiredField(),\n                      message: 'Obrigatório',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"travel-input",attrs:{"placeholder":"Razão Social"}},[_c('template',{slot:"prefix"},[_vm._v("#"+_vm._s(airline.id))])],2)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":6}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(
                    `flight_${_vm.flightSectionID}_airline_trading_name_${airline.id}`
                  )
                    ? 'filled'
                    : ''},[_vm._v("Nome Fantasia")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionID}_airline_trading_name_${airline.id}`,
                  {
                    rules: [
                      {
                        required: _vm.dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `flight_${flightSectionID}_airline_trading_name_${airline.id}`,\n                  {\n                    rules: [\n                      {\n                        required: dynamicRequiredField(),\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome Fantasia"}})],1)])],1),_vm._v(" "),_c('a-col',{attrs:{"span":7}},[_c('a-form-item',[_c('span',{staticClass:"travel-input"},[_c('label',{class:_vm.form.getFieldValue(
                    `flight_${_vm.flightSectionID}_airline_CNPJ_${airline.id}`
                  )
                    ? 'filled'
                    : ''},[_vm._v("CNPJ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"},{name:"decorator",rawName:"v-decorator",value:([
                  `flight_${_vm.flightSectionID}_airline_CNPJ_${airline.id}`,
                  {
                    rules: [
                      {
                        required: _vm.dynamicRequiredField(),
                        message: 'Obrigatório',
                      },
                    ],
                  },
                ]),expression:"[\n                  `flight_${flightSectionID}_airline_CNPJ_${airline.id}`,\n                  {\n                    rules: [\n                      {\n                        required: dynamicRequiredField(),\n                        message: 'Obrigatório',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"travel-input",attrs:{"placeholder":"CNPJ"}})],1)])],1),_vm._v(" "),_c('a-col',{staticStyle:{"text-align":"center"},attrs:{"span":1}},[(
              airline.id === _vm.flight_airlines.length &&
              _vm.flight_airlines.length > 1
            )?_c('a-button',{attrs:{"size":"small","type":"danger","shape":"circle","icon":"delete"},on:{"click":function($event){return _vm.removeRow(airline.id)}}}):_vm._e(),_vm._v(" "),(airline.id == 1)?_c('a-tooltip',{attrs:{"placement":"topRight","title":"Selecionar cia aérea"}},[_c('a-button',{staticStyle:{"left":"-2px","top":"8px"},attrs:{"size":"small","type":"primary","shape":"circle","disabled":true}},[_c('i',{staticClass:"fas fa-plane"})])],1):_vm._e()],1)],1)})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }